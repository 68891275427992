import { h, Component } from 'preact'
import PropTypes from 'prop-types'
import { bindToAppState } from 'lib/appState'

import Layout from 'components/Layout'
import SideNav from 'components/SideNav'

import './index.sass'

class BobLayout extends Component {

  static propTypes = {
    location: PropTypes.object.isRequired,
    organizationProfile: PropTypes.object.isRequired,
    crumbs: PropTypes.array,
  }

  toggleConsumerDropdown = () => {
    sessionStorage.consumerDropdownClosed = (
      sessionStorage.consumerDropdownClosed === "1" ? "0" : "1"
    )
    this.forceUpdate()
  }

  toggleBusinessDropdown = () => {
    sessionStorage.businessDropdownClosed = (
      sessionStorage.businessDropdownClosed === "0" ? "1" : "0"
    )
    this.forceUpdate()
  }

  toggleNetworkAdminDropdown = () => {
    sessionStorage.networkAdminDropdownOpen = (
      sessionStorage.networkAdminDropdownOpen === "1" ? "0" : "1"
    )
    this.forceUpdate()
  }

  render({
    appAction,
    className = '',
    crumbs,
    children,
    organizationProfile,
    location,
    currentUser = {},
    ...props
  }){

    const businessDropdownOpen = sessionStorage.businessDropdownClosed === "0"
    const consumerDropdownOpen = sessionStorage.consumerDropdownClosed !== "1"
    const networkAdminDropdownOpen = sessionStorage.networkAdminDropdownOpen !== "1"

    const sideNav = <BobLayoutSideNav
      isNetwork={!!organizationProfile.is_network}
      toggleBusinessDropdown={this.toggleBusinessDropdown}
      toggleConsumerDropdown={this.toggleConsumerDropdown}
      businessDropdownOpen={businessDropdownOpen}
      consumerDropdownOpen={consumerDropdownOpen}
      networkAdminDropdownOpen={networkAdminDropdownOpen}
      toggleNetworkAdminDropdown={this.toggleNetworkAdminDropdown}
      location={location}
    />

    return <Layout
      className={`BobLayout ${className || ''}`}
      currentUser={currentUser}
      logout={appAction('session.logout')}
      crumbs={crumbs}
      sideNav={sideNav}
      {...props}
    >
      {children}
    </Layout>
  }
}

export default bindToAppState(
  [
    'currentUser',
    'organizationProfile',
  ],
  BobLayout,
)

const BobLayoutSideNav = ({
  // isNetwork,
  consumerDropdownOpen,
  // businessDropdownOpen,
  toggleConsumerDropdown,
  // toggleBusinessDropdown,
  location,
  // networkAdminDropdownOpen,
  // toggleNetworkAdminDropdown,
}) => (
  <SideNav>
    <SideNav.Header className="BobLayout-adminHeader">ADMIN</SideNav.Header>
    <SideNav.Header>NAVIGATION</SideNav.Header>
    <SideNav.Button
      href="/"
      icon="cog"
      value="Set-Up"
      selected={location.pathname === '/'}
    />
    <SideNav.Dropdown
      icon="master-data"
      value="Individuals"
      open={consumerDropdownOpen}
      onClick={toggleConsumerDropdown}
    >
      <SideNav.Dropdown.Option
        href="/c/user-data"
        selected={location.pathname === '/c/user-data'}
      >
        User Data
      </SideNav.Dropdown.Option>
      <SideNav.Dropdown.Option
        href="/c/agreements"
        selected={location.pathname === '/c/agreements'}
      >
        Data Agreements
      </SideNav.Dropdown.Option>
      <SideNav.Dropdown.Option
        href="/c/market/sell"
        selected={location.pathname === '/c/market/sell'}
      >
        Marketplace Sell
      </SideNav.Dropdown.Option>
      {/*<SideNav.Dropdown.Option
        href="/c/feed"
        selected={location.pathname === '/c/feed'}
      >
        Brand & Posts
      </SideNav.Dropdown.Option>*/}
      <SideNav.Dropdown.Option
        href="/c/admin"
        selected={location.pathname === '/c/admin'}
      >
        Admin Dashboard
      </SideNav.Dropdown.Option>
    </SideNav.Dropdown>
    {/* <SideNav.Dropdown
      icon="globe"
      value="Business"
      open={businessDropdownOpen}
      onClick={toggleBusinessDropdown}
    >
      <SideNav.Dropdown.Option
        href="/b/agreements"
        selected={location.pathname === '/b/agreements'}
      >
        Agreements
      </SideNav.Dropdown.Option>
      <SideNav.Dropdown.Option
        href="/b/orgs"
        selected={location.pathname === '/b/orgs'}
      >
        Organizations
      </SideNav.Dropdown.Option>
      <SideNav.Dropdown.Option
        href="/b/market/buy"
        selected={location.pathname === '/b/market/buy'}
      >
        Marketplace Buy
      </SideNav.Dropdown.Option>
      <SideNav.Dropdown.Option
        href="/b/market/sell"
        selected={location.pathname === '/b/market/sell'}
      >
        Marketplace Sell
      </SideNav.Dropdown.Option>
      <SideNav.Dropdown.Option
        href="/b/feed"
        selected={location.pathname === '/b/feed'}
      >
        Brand & Posts
      </SideNav.Dropdown.Option>
      <SideNav.Dropdown.Option
        href="/b/admin"
        selected={location.pathname === '/b/admin'}
      >
        Admin Dashboard
      </SideNav.Dropdown.Option>
    </SideNav.Dropdown> */}
    {/*<SideNav.Button
      href="/networks"
      icon="spaces"
      value="Networks"
      selected={location.pathname === '/networks'}
    />*/}
    {/*
      isNetwork &&
      <SideNav.Dropdown
        icon="network"
        value="Network Admin"
        open={networkAdminDropdownOpen}
        onClick={toggleNetworkAdminDropdown}
      >
        <SideNav.Dropdown.Option
          href="/n/members"
          selected={location.pathname === '/n/members'}
        >
          Members
        </SideNav.Dropdown.Option>
        <SideNav.Dropdown.Option
          href="/n/feed"
          selected={location.pathname === '/n/feed'}
        >
          Brand & Posts
        </SideNav.Dropdown.Option>
        <SideNav.Dropdown.Option
          href="/n/admin"
          selected={location.pathname === '/n/admin'}
        >
          Admin Dashboard
        </SideNav.Dropdown.Option>
      </SideNav.Dropdown>
    */}
  </SideNav>
)

BobLayoutSideNav.propTypes = {
  location: PropTypes.object.isRequired,
  toggleBusinessDropdown: PropTypes.func,
  toggleConsumerDropdown: PropTypes.func,
  toggleNetworkAdminDropdown: PropTypes.func,
  businessDropdownOpen: PropTypes.bool,
  consumerDropdownOpen: PropTypes.bool,
  networkAdminDropdownOpen: PropTypes.bool,
  isNetwork: PropTypes.bool,
}
