import { h, Fragment } from 'preact'
import { useState, useCallback, useEffect, useRef } from 'preact/hooks'
import PropTypes from 'prop-types'
import { TransitionGroup, CSSTransition } from 'react-transition-group'

import classNames from 'lib/classNames'
import {
  fileToImageDataURL,
  loadImage,
  deanimateImage,
  aspectRatioToTopPadding,
} from 'lib/imageHelpers'
import { usePortal } from 'lib/portal'
import { preventFocusOut, onThisKeyDown } from 'lib/DOMHelpers'

import Icon from 'components/Icon'
import ButtonRow from 'components/ButtonRow'
import Button from 'components/Button'
import Overlay from 'components/Overlay'
import CropImage from 'components/CropImage'

import './index.sass'

export default function CropImageInput({
  className,
  circle,
  height,
  width,
  minZoom,
  type,
  backgroundFillStyle,
  encoderOptions,
  title,
  disabled,
  unsaved,
  defaultValue,
  value,
  onChange,
  ...props
}){
  const rootRef = useRef()
  const [draggedOver, setDraggedOver] = useState()
  const [file, setFile] = useState()
  const [image, setImage] = useState()
  const [croppedImage, setCroppedImage] = useState()
  const [error, setError] = useState()

  const reading = (!error && file && !image)
  const cropping = (!error && !disabled && image && !croppedImage)

  const reset = () => {
    setDraggedOver()
    setFile()
    setImage()
    setCroppedImage()
    setError()
  }

  useEffect(reset, [value])

  useEffect(
    () => {
      if (cropping) return preventFocusOut(rootRef.current)
    },
    [cropping]
  )

  useEffect(
    () => {
      if (cropping) return onThisKeyDown(rootRef.current, 'Escape', reset)
    },
    [cropping]
  )

  const selectFile = useCallback(
    file => {
      if (!(file instanceof global.File)) return
      setFile(file)
      setError(null)
      fileToImageDataURL(file)
        // we need the loadImage here to throw an error when
        // the file is not a valid image
        .then(loadImage)
        .then(deanimateImage)
        .then(
          image => {
            setImage(image)
          },
          error => {
            console.error('CropImageInput', error)
            setError(error)
          },
        )
    },
    [],
  )
  const onDragOver = useCallback(
    event => {
      event.preventDefault()
      setDraggedOver(true)
    },
    [],
  )
  const onDragLeave = useCallback(
    event => {
      event.preventDefault()
      setDraggedOver(false)
    },
    [],
  )
  const onDrop = useCallback(
    event => {
      event.preventDefault()
      reset()
      selectFile(event.dataTransfer.files[0])
    },
    [selectFile],
  )

  let displayWidth =
    (!disabled && image && image.width > width) ? image.width : width
  // this prevents the buttons from overflowing
  if (cropping && displayWidth < 200) displayWidth = 124
  const small = displayWidth < 150

  className = classNames('CropImageInput', {
    disabled,
    circle,
    small,
    cropping,
    draggedOver: !disabled && draggedOver,
    unsaved,
    className,
  })

  const src = (
    (!disabled && image && image.src) ||
    value ||
    defaultValue
  )

  return <div {...{
    ...props,
    className,
    onDragOver: disabled ? undefined : onDragOver,
    onDragLeave: disabled ? undefined : onDragLeave,
    onDrop: disabled ? undefined : onDrop,
    ref: rootRef,
  }}>
    <Shroud open={cropping} />
    {
      cropping
        ? <Cropping {...{
          image,
          circle,
          height,
          width,
          minZoom,
          type,
          backgroundFillStyle,
          encoderOptions,
          onCancel: reset,
          onCrop: croppedImage => {
            onChange(croppedImage)
          },
        }}/>
        : <Image {...{
          src,
          height,
          width,
        }}/>
    }
    <OurOverlay {...{
      disabled, small, cropping, draggedOver, reading, error,
    }}/>
    {!disabled && !cropping &&
      <FileInput {...{disabled, title, selectFile}}/>
    }
  </div>
}

CropImageInput.propTypes = {
  className: PropTypes.string,
  circle: PropTypes.bool,
  disabled: PropTypes.bool,
  unsaved: PropTypes.bool,
  title: PropTypes.string,
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  minZoom: CropImage.propTypes.minZoom,
  type: CropImage.propTypes.type,
  backgroundFillStyle: CropImage.propTypes.backgroundFillStyle,
  encoderOptions: CropImage.propTypes.encoderOptions,
  defaultValue: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(global.File),
  ]),
  onChange: PropTypes.func.isRequired,
}

function Image({src, height, width}){
  const style = {
    backgroundImage: `url(${src})`,
    width: `${width}px`,
    paddingTop: aspectRatioToTopPadding(height, width),
  }
  return <div className="CropImageInput-image" {...{style}}/>
}

function OurOverlay({
  disabled, small, cropping, draggedOver, reading, error,
}){
  if (disabled && !error) return null
  if (cropping && !draggedOver) return null
  const hidden = !error && !draggedOver && !reading
  return <Overlay {...{small, hidden}}>
    <Icon type="upload" />
    <span>{
      error ? (small ? 'Error' : 'Error reading file') :
      reading ? (small ? 'Reading…' : 'Reading File…') :
      draggedOver ? (small ? 'DROP!' : 'Drop a file!') :
      (small ? '' : 'Upload a file')
    }</span>
  </Overlay>
}

function FileInput({disabled, title, selectFile}){
  const onChange = useCallback(
    event => {
      selectFile(event.target.files[0])
      event.target.value = null
    },
    [selectFile]
  )
  return <input
    className="CropImageInput-fileInput"
    disabled={disabled}
    type="file"
    accept="image/*"
    onChange={disabled ? undefined : onChange}
    title={title || 'Click here to upload a new image'}
  />
}

function Cropping({
  image,
  circle,
  height,
  width,
  minZoom,
  type,
  backgroundFillStyle,
  encoderOptions,
  onCancel,
  onCrop,
}){
  const [croppedDataURL, setCroppedDataURL] = useState()
  return <Fragment>
    <CropImage {...{
      image,
      circle,
      height,
      width,
      minZoom,
      type,
      backgroundFillStyle,
      encoderOptions,
      onChange: setCroppedDataURL,
    }}/>
    <ButtonRow>
      <Button
        type="normal"
        value="cancel"
        onClick={onCancel}
      />
      <Button
        type="primary"
        value="crop"
        disabled={!croppedDataURL}
        onClick={event => {
          onCrop(croppedDataURL)
          // this makes things feel faster
          event.target.parentElement.parentElement.removeChild(event.target.parentElement)
        }}
      />
    </ButtonRow>
  </Fragment>
}

function Shroud({ open }) {
  const renderInPortal = usePortal()
  const shroud = open && <CSSTransition
    timeout={200}
    classNames="CropImageInput-Shroud-animation"
  >
    <div className="CropImageInput-Shroud" />
  </CSSTransition>
  return renderInPortal(
    <TransitionGroup>{shroud}</TransitionGroup>
  )
}
