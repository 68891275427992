import { h, Fragment, cloneElement } from 'preact'
import { Children } from 'preact/compat'
import { useErrorBoundary, useRef, useEffect } from 'preact/hooks'
import PropTypes from 'prop-types'

import { usePortal } from 'lib/portal'
import classNames from 'lib/classNames'
import history from 'lib/history'

import './index.sass'

const OFFSET_X = 10
const OFFSET_Y = 10
const FADE_IN_DELAY = 1500

export default function Tooltip({className, text, children, ...props}){
  const renderInPortal = usePortal()
  const childrenRef = useRef([])
  const bubbleRef = useRef()

  useEffect(
    () => {
      if (!bubbleRef.current || !bubbleRef.current.base) return
      const bubble = bubbleRef.current.base

      let mouseClientX = 0
      let mouseClientY = 0

      const isVisible = () =>
        bubble.classList.contains('Tooltip-visible')

      const onMouseMove = event => {
        mouseClientX = event.clientX
        mouseClientY = event.clientY
      }
      let timeout
      const showBubble = () => {
        if (isVisible()) return
        let top = mouseClientY + OFFSET_Y
        let left = mouseClientX + OFFSET_X
        const elementWidth = bubble.clientWidth
        const windowWidth = window.innerWidth
        const right = left + elementWidth
        const overflow = right - windowWidth
        if (overflow > 0) left -= overflow
        bubble.style.top = `${top}px`
        bubble.style.left = `${left}px`
        bubble.classList.add('Tooltip-visible')
        bubble.ownerDocument.removeEventListener('mousemove', onMouseMove)
      }
      const hideBubble = () => {
        bubble.classList.remove('Tooltip-visible')
      }
      const onMouseDown = () => {
        clearTimeout(timeout)
      }
      const onMouseEnter = event => {
        mouseClientX = event.clientX
        mouseClientY = event.clientY
        clearTimeout(timeout)
        bubble.ownerDocument.addEventListener('mousemove', onMouseMove)
        timeout = setTimeout(showBubble, FADE_IN_DELAY)
      }
      const onMouseLeave = () => {
        clearTimeout(timeout)
        timeout = setTimeout(hideBubble, 10)
      }
      childrenRef.current.forEach(element => {
        element.addEventListener('mousedown', onMouseDown)
        element.addEventListener('mouseenter', onMouseEnter)
        element.addEventListener('mouseleave', onMouseLeave)
      })
      return () => {
        childrenRef.current.forEach(element => {
          element.removeEventListener('mousedown', onMouseDown)
          element.removeEventListener('mouseenter', onMouseEnter)
          element.removeEventListener('mouseleave', onMouseLeave)
        })
      }
    },
    childrenRef.current
  )

  if (!children) return
  if (!Array.isArray(children)) children = [children]
  children = Children.map(children, child => {
    if (typeof child === 'string' || typeof child === 'number')
      child = <span>{child}</span>
    return cloneElement(child, {
      ref: element => {
        if (!element) return
        while (element.base) element = element.base
        childrenRef.current.push(element)
      },
    })
  })

  const bubble = <Bubble {...{
    ref: bubbleRef,
    className: classNames('Tooltip', { className }),
    ...props,
  }}>{text}</Bubble>
  return <Fragment>
    {renderInPortal(bubble)}
    {children}
  </Fragment>
}


Tooltip.propTypes = {
  children: PropTypes.node.isRequired,
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
}

function Bubble({ children }){
  const [error] = useErrorBoundary()
  if (error) console.error('Tooltip render error', error)
  return <div className="Tooltip-Bubble">{error || children}</div>
}

function hideAllBubbles(){
  const bubbles = Array.from(window.document.querySelectorAll('.Tooltip-Bubble'))
  bubbles.forEach(bubble =>
    bubble.classList.remove('Tooltip-visible')
  )
}
// on page change hide all bubbles
history.subscribe(hideAllBubbles)
