import { h } from 'preact'
import PropTypes from 'prop-types'

import classNames from 'lib/classNames'

import './index.sass'
import './icons.scss'
import FONTELLO_ICONS from './icons.json'
import SVG_ICONS from './svg-icons'

export default function Icon({
  className,
  type,
  value,
  size,
  rotate,
  spin,
  transitionRotation,
  blue,
  subtle,
  ...props
}){
  const invalidType = type && !Icon.types.includes(type)
  const svg = type && SVG_ICONS[type]
  if (invalidType) console.warn(`Invalid Icon type "${type}"`)
  className = classNames('Icon', {
    className,
    spin,
    blue,
    subtle,
    transitionRotation,
    svg,
  })
  if (type) className += ` Icon-${type}`
  if (size) className += ` Icon-${size}`
  if (rotate) className += ` Icon-rotate-${rotate}`
  if (svg) value = svg
  if (!value && (!type || invalidType)) {
    value = '❓'
    props.title = type
  }
  props.className = className
  return <i {...props} aria-hidden>{value}</i>
}

Icon.types = Object.freeze([
  'none',
  ...Object.keys(FONTELLO_ICONS),
  ...Object.keys(SVG_ICONS),
])

Icon.sizes = Object.freeze([
  'xs',
  'sm',
  'lg',
  // 'hg',
])

Icon.propTypes = {
  type: PropTypes.oneOf(Icon.types),
  value: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.oneOf(Icon.sizes),
  rotate: PropTypes.oneOf([0, 45, 90, 180, 270]),
  transitionRotation: PropTypes.bool,
  onClick: PropTypes.func,
  spin: PropTypes.bool,
  blue: PropTypes.bool,
  subtle: PropTypes.bool,
}
