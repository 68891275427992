/* eslint-disable max-len */
import { h } from 'preact'

export default {
  create: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
      <path d="M36.075,24a2.5,2.5,0,0,1-2.5,2.5H26.5v7.071a2.5,2.5,0,0,1-5,0V26.5h-7.07a2.5,2.5,0,0,1,0-5H21.5V14.433a2.5,2.5,0,1,1,5,0V21.5h7.071A2.5,2.5,0,0,1,36.075,24ZM48,24A24,24,0,1,1,24,0,23.993,23.993,0,0,1,48,24Zm-5,0A19,19,0,1,0,24,43,19.021,19.021,0,0,0,43,24Z" fill="#231f20"/>
    </svg>
  ),
  home: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 47.38 45">
      <path d="M44.673,20.061H41.917V42.575A2.425,2.425,0,0,1,39.492,45H29.123V30.29H18.252V45H7.89a2.425,2.425,0,0,1-2.424-2.425V20.061H2.706a2.7,2.7,0,0,1-1.52-4.934l22-14.971a.9.9,0,0,1,1.013,0l22,14.971A2.7,2.7,0,0,1,44.673,20.061Z" fill="#231f20"/>
    </svg>
  ),
  hubs: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 47.022 43.499">
      <path d="M16.08,5.448a2.711,2.711,0,0,1,2.712-2.713H44.309a2.713,2.713,0,1,1,0,5.425H18.792A2.712,2.712,0,0,1,16.08,5.448Zm0,16.316a2.711,2.711,0,0,1,2.712-2.712H44.309a2.713,2.713,0,1,1,0,5.425H18.792A2.712,2.712,0,0,1,16.08,21.764Zm0,16.288a2.711,2.711,0,0,1,2.712-2.713H44.309a2.713,2.713,0,1,1,0,5.425H18.792A2.712,2.712,0,0,1,16.08,38.052ZM5.448,16.317A5.448,5.448,0,1,0,10.9,21.764,5.448,5.448,0,0,0,5.448,16.317Zm0,16.287A5.448,5.448,0,1,0,10.9,38.052,5.448,5.448,0,0,0,5.448,32.6Zm0-32.6A5.448,5.448,0,1,0,10.9,5.448,5.448,5.448,0,0,0,5.448,0Z" fill="#231f20"/>
    </svg>
  ),
  messages: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48.75 45.114">
      <path d="M41.37,5.59H38.84A9.962,9.962,0,0,0,29.89,0H9.98A10,10,0,0,0,0,9.98V23.26a9.994,9.994,0,0,0,5.69,9L4.64,35.71a4.975,4.975,0,0,0,2.1,5.67,4.913,4.913,0,0,0,2.68.78,4.981,4.981,0,0,0,3.35-1.28l4.6-4.15H29.09a.558.558,0,0,1,.22.03c.09.03.14.05.18.06l10.342,7.974a1.238,1.238,0,0,0,1.49.13,1.248,1.248,0,0,0,.52-1.41L40.58,37.4a.83.83,0,0,1,.79-.67,7.379,7.379,0,0,0,7.38-7.37V12.97A7.375,7.375,0,0,0,41.37,5.59ZM11.94,34.88,9.42,37.16l1.17-3.85,1.04-3.42a1.66,1.66,0,0,0-1.65-1.66,3.313,3.313,0,0,1-.55-.04A4.956,4.956,0,0,1,5,23.26V9.98A4.987,4.987,0,0,1,9.98,5H29.89a4.905,4.905,0,0,1,2.35.59,5,5,0,0,1,2.62,4.39V23.26a4.976,4.976,0,0,1-4.97,4.97H19.93a1.521,1.521,0,0,0-.47.07,1.583,1.583,0,0,0-.45.21Z" fill="#231f20"/>
    </svg>
  ),
  notifications: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 46.979 47.23">
      <path d="M23.491,5h0A14.565,14.565,0,0,1,38.069,19.54v6.88A12.372,12.372,0,0,0,41.593,35.2a1.188,1.188,0,0,1,.386.865,1.281,1.281,0,0,1-1.28,1.28h-9.23a5,5,0,0,0-4.448,2.716,3.965,3.965,0,0,1-7.075,0A5,5,0,0,0,15.5,37.34H6.269A1.267,1.267,0,0,1,5.1,36.562a1.251,1.251,0,0,1,.285-1.375A11.914,11.914,0,0,0,8.9,26.86V19.74A14.584,14.584,0,0,1,22.075,5.068c.47-.045.946-.068,1.416-.068m0-5Q22.548,0,21.6.09A19.556,19.556,0,0,0,3.9,19.74v7.12a6.934,6.934,0,0,1-2.09,4.83,6.255,6.255,0,0,0,4.46,10.65H15.5a8.964,8.964,0,0,0,15.97,0H40.7a6.284,6.284,0,0,0,6.28-6.28,6.178,6.178,0,0,0-1.88-4.43,7.339,7.339,0,0,1-2.03-5.21V19.54A19.563,19.563,0,0,0,23.49,0Z" fill="#231f20"/>
    </svg>
  ),
  shareplane: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 46.713 45.296">
      <path d="M6.445,45.3a6.122,6.122,0,0,1-4.926-2.253A9.806,9.806,0,0,1,.339,35.2l2.794-9.781a2.33,2.33,0,0,1,1.9-1.667l5.525-.837c-6.531-1.029-6.872-1.738-7.287-2.605a2.309,2.309,0,0,1-.208-.716L.339,10.065A9.8,9.8,0,0,1,1.518,2.221C3.11.1,6.683-.631,9.382.594L42.1,15.464a8.326,8.326,0,0,1,4.612,7.166A8.327,8.327,0,0,1,42.1,29.8L9.381,44.667A7.151,7.151,0,0,1,6.445,45.3Zm.767-17.155L4.83,36.478a5.3,5.3,0,0,0,.427,3.761,2.427,2.427,0,0,0,2.19.176l32.719-14.87a3.766,3.766,0,0,0,1.875-2.915,3.768,3.768,0,0,0-1.875-2.915L7.449,4.846a2.429,2.429,0,0,0-2.194.178A5.3,5.3,0,0,0,4.83,8.783l2.511,8.791c2.96.7,12.438,1.884,20.919,2.732a2.335,2.335,0,0,1,.116,4.633Z" fill="#231f20"/>
    </svg>
  ),
  singlecomment: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39.86 42.16">
      <g>
        <path d="M9.98,5a4.889,4.889,0,0,0-1.49.23,5.2,5.2,0,0,1,1.48-.22h9.96A1.623,1.623,0,0,0,20.14,5Zm-1.49.23a4.551,4.551,0,0,0-.88.37A6.815,6.815,0,0,1,8.49,5.23ZM5.69,32.27v-.01a11.028,11.028,0,0,1-1.21-.68A9,9,0,0,0,5.69,32.27ZM32.24,5.59a1.072,1.072,0,0,0-.22-.1c.16.08.32.17.47.26C32.41,5.69,32.32,5.64,32.24,5.59Zm-.22-.1a4.1,4.1,0,0,0-1.59-.44A5,5,0,0,1,32.02,5.49ZM29.77,5a.375.375,0,0,0,.11.01,2.62,2.62,0,0,1,.28.01A1.229,1.229,0,0,0,29.89,5Z" fill="#231f20"/>
        <path d="M38.63,5.19c-.11-.2-.23-.4-.36-.59s-.26-.39-.39-.57a11.313,11.313,0,0,0-.9-1.05c-.17-.16-.33-.32-.51-.48a11.262,11.262,0,0,0-1.09-.84A11.028,11.028,0,0,0,34.17.98V.97A9.881,9.881,0,0,0,29.89,0H9.98A10,10,0,0,0,0,9.98V23.26a9.809,9.809,0,0,0,1.02,4.39c.07.14.14.27.21.4.11.2.23.4.36.59s.26.39.39.57a11.313,11.313,0,0,0,.9,1.05c.17.16.33.32.51.48a11.262,11.262,0,0,0,1.09.84,11.028,11.028,0,0,0,1.21.68v.01L4.64,35.71a4.975,4.975,0,0,0,2.1,5.67,4.913,4.913,0,0,0,2.68.78,4.981,4.981,0,0,0,3.35-1.28l8.28-7.64h8.83a10,10,0,0,0,9.98-9.98V9.98A9.835,9.835,0,0,0,38.63,5.19ZM34.86,23.26a4.91,4.91,0,0,1-1.46,3.51,4.619,4.619,0,0,1-1.14.86.01.01,0,0,1-.01.01,4.551,4.551,0,0,1-.88.37,4.889,4.889,0,0,1-1.49.23H19.72a1.378,1.378,0,0,0-.26.06,1.583,1.583,0,0,0-.45.21l-5.25,4.73-1.82,1.64L9.42,37.16l1.17-3.85.02-.07,1.02-3.35a1.652,1.652,0,0,0-1.54-1.65H9.97a1.229,1.229,0,0,1-.27-.02c-.05,0-.11-.01-.16-.01a4.427,4.427,0,0,1-1.7-.46,1.072,1.072,0,0,1-.22-.1c-.08-.05-.17-.1-.25-.16a.551.551,0,0,1-.14-.09,1.941,1.941,0,0,1-.29-.21,2.644,2.644,0,0,1-.41-.34,4.7,4.7,0,0,1-.42-.47,4.538,4.538,0,0,1-.36-.5,1.74,1.74,0,0,1-.18-.33l-.15-.3c-.04-.1-.08-.2-.12-.31A4.679,4.679,0,0,1,5,23.26V9.98A4.951,4.951,0,0,1,6.46,6.47,5.024,5.024,0,0,1,7.6,5.61a.01.01,0,0,0,.01-.01,4.551,4.551,0,0,1,.88-.37A4.889,4.889,0,0,1,9.98,5H29.89a1.229,1.229,0,0,1,.27.02,2.477,2.477,0,0,1,.27.03,4.1,4.1,0,0,1,1.59.44,1.072,1.072,0,0,1,.22.1c.08.05.17.1.25.16.05.03.1.06.14.09a1.941,1.941,0,0,1,.29.21,4.624,4.624,0,0,1,.41.34,6.142,6.142,0,0,1,.42.47,4.2,4.2,0,0,1,.36.5,2.685,2.685,0,0,1,.18.33,2.1,2.1,0,0,1,.15.3,1.978,1.978,0,0,1,.12.31,4.718,4.718,0,0,1,.3,1.68Z" fill="#231f20"/>
      </g>
    </svg>
  ),
  truline: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45.548 45.548">
      <path d="M31.748,4.823h8.977v4.49A4.486,4.486,0,0,1,36.239,13.8H27.262V40.725H22.771a4.486,4.486,0,0,1-4.485-4.488V13.8H4.823V9.308A4.486,4.486,0,0,1,9.309,4.823H22.777A4.487,4.487,0,0,1,27.262,9.31v0a4.486,4.486,0,0,1,4.486-4.485M40.725,0H31.75a9.259,9.259,0,0,0-4.482,1.151A9.255,9.255,0,0,0,22.778,0H9.31A9.32,9.32,0,0,0,0,9.306V13.8a4.823,4.823,0,0,0,4.823,4.823h8.64V36.237a9.321,9.321,0,0,0,9.307,9.311h4.492a4.822,4.822,0,0,0,4.822-4.823v-22.1h4.153a9.321,9.321,0,0,0,9.311-9.306V4.823A4.823,4.823,0,0,0,40.725,0Z" fill="#231f20"/>
    </svg>
  ),
}
