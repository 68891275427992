import {
  COUNTRIES,
  getCountryCallingCode,
  humanizePartialPhoneNumber,
  normalizePartialPhoneNumber,
  getCountryCodesForPartialPhoneNumber,
  stripCountryCallingCode,
} from 'lib/phone'

import { h, Component } from 'preact'
import PropTypes from 'prop-types'

import TextInput from 'components/TextInput'
import './index.sass'

export default class PhoneInput extends Component {
  static propTypes = {
    ...TextInput.propTypes,
    onCountryChange: PropTypes.func,
    autocomplete: PropTypes.string,
  }

  static defaultProps = {
    defaultCountryCode: isDataYogi ? 'GB' : 'US',
    maxLength: 20,
    title: 'international mobile phone number',
    pattern: '^\\+\\d+\\s+[\\s\\d]{7,20}',
    placeholder: 'Mobile Phone',
    autocomplete: 'tel',
  }

  constructor(props){
    super()
    const countryCodes = props.value
      ? getCountryCodesForPartialPhoneNumber(props.value)
      : []
    this.state = {
      selectedCountryCode: countryCodes && countryCodes[0] || null,
    }
  }

  selectCountryCode(selectedCountryCode){
    this.setState({ selectedCountryCode })
    if (this.props.onCountryChange)
      this.props.onCountryChange(selectedCountryCode)
  }

  onCountryCodeChange = countryCode => {
    const countryCallingCode = getCountryCallingCode(countryCode)
    const oldValue = this.props.value
    const newValue = oldValue
      ? `+${countryCallingCode}${stripCountryCallingCode(oldValue)}`
      : `+${countryCallingCode}`
    if (this.props.onChange) this.props.onChange(newValue)
    if (this.props.onInput) this.props.onInput(newValue)
    this.selectCountryCode(countryCode)
    setTimeout(() => {
      const input = this.base.querySelector('input')
      if (input) input.focus()
    }, 10)
  }

  onPhoneChange = (value) => {
    if (this.props.onChange) this.props.onChange(normalizePartialPhoneNumber(value))
  }

  onPhoneInput = (value) => {
    if (!this.props.onInput) return
    const { selectedCountryCode } = this.state
    let normalizedValue = normalizePartialPhoneNumber(value)
    if (value.slice(-1) === ' ') normalizedValue += ' '
    if (normalizedValue && normalizedValue[0] !== '+') normalizedValue = `+${normalizedValue}`
    const countryCodes = getCountryCodesForPartialPhoneNumber(normalizedValue)
    if (!selectedCountryCode || !countryCodes.includes(selectedCountryCode) && countryCodes[0]){
      this.selectCountryCode(countryCodes[0])
    }
    this.props.onInput(normalizedValue)
  }

  onPhoneFocus = event => {
    const { value, defaultCountryCode } = this.props
    if (!value){
      const { selectedCountryCode } = this.state
      const countryCode = selectedCountryCode || defaultCountryCode
      this.base.querySelector('input').value = countryCode
        ? `+${getCountryCallingCode(countryCode)} `
        : '+ '
    }
    if (this.props.onFocus) this.props.onFocus(event)
  }

  onPhoneBlur = event => {
    if (!this.props.value && this.base){
      const input = this.base.querySelector('input')
      if (input) input.value = ''
    }
    if (this.props.onBlur) this.props.onBlur(event)
  }

  render(){
    const {
      className = '',
      disabled,
      defaultCountryCode,
      value,
      autocomplete,
      ...props
    } = this.props
    const { selectedCountryCode } = this.state
    const countryCode = selectedCountryCode || defaultCountryCode

    let formattedValue = null
    if (value){
      formattedValue = humanizePartialPhoneNumber(value, countryCode)
      if (value.slice(-1) === ' ') formattedValue += ' '
    }

    return <div className={`PhoneInput ${className}`}>
      <CountrySelect
        value={countryCode}
        disabled={disabled}
        onChange={this.onCountryCodeChange}
        autocomplete="tel-country-code"
      />
      <TextInput
        {...props}
        ref={input => { if (input) this.input = input }}
        type="tel"
        autocomplete={autocomplete}
        value={formattedValue}
        disabled={disabled}
        onChange={this.onPhoneChange}
        onInput={this.onPhoneInput}
        onFocus={this.onPhoneFocus}
        onBlur={this.onPhoneBlur}
      />
    </div>
  }
}

function CountrySelect({value, disabled, onChange, ...props}){
  const options = COUNTRIES
    .filter(country => !!country.callingCode)
    .map(country =>
      <option
        key={country.countryCode}
        selected={country.countryCode === value}
        value={country.countryCode}
      >{country.name} +{country.callingCode}</option>
    )
  if (!value) options.unshift(<option key="null" selected />)
  let className = 'PhoneInput-CountrySelect'
  if (disabled) className += ' PhoneInput-CountrySelect-disabled'
  return <div className={className}>
    <div className="PhoneInput-CountrySelect-value">{value}</div>
    <select
      {...props}
      disabled={disabled}
      onChange={event => { onChange(event.target.value) }}
    >
      {options}
    </select>
  </div>
}
