import { h } from 'preact'
import { useCallback, useState, useEffect} from 'preact/hooks'
import PropTypes from 'prop-types'

import classNames from 'lib/classNames'

import TextInputWithIcon from 'components/TextInputWithIcon'
import Dropdown from 'components/Dropdown'

import './index.sass'

const VALID_CURRENCIES = Object.freeze(['$', '€', '£', '¥'])

const floatToInteger = float =>
  Math.round(parseFloat(float, 10) * 100)

const valueToFloat = value =>
  typeof value !== 'number'
    ? undefined
    : `${(value / 100).toFixed(2)}`


export default function PriceInput({
  className,
  disabled,
  currency,
  onCurrencySelect,
  value,
  currencies,
  ...props
}) {

  const [inputValue, setInputValue] = useState(valueToFloat(value))

  useEffect(
    () => {
      if (floatToInteger(inputValue) !== value)
        setInputValue(valueToFloat(value))
    },
    [value]
  )

  const filteredCurrencies = currencies
    ? currencies.filter(currency => VALID_CURRENCIES.includes(currency))
    : VALID_CURRENCIES

  const currencyPicker = filteredCurrencies.length > 1
    ? <Dropdown
      tabIndex={-1}
      options={filteredCurrencies}
      disabled={disabled}
      value={currency}
      onChange={onCurrencySelect}
      fullWidth
      noIcon
    />
    : <div className="PriceInput-singleCurrency">{filteredCurrencies[0]}</div>

  const onFocus = useCallback(
    event => {
      event.target.select()
      if (props.onFocus) return props.onFocus(event)
    },
    [props.onFocus],
  )

  const onInput = useCallback(
    float => {
      setInputValue(float)
      float = float.replace(/(\.\d\d)\d*/, '$1')
      if (props.onInput) props.onInput(floatToInteger(float))
    },
    [props.onInput],
  )

  const onChange = useCallback(
    float => {
      setInputValue(valueToFloat(floatToInteger(float)))
      if (props.onChange) return props.onChange(floatToInteger(float))
      else if (props.onInput) return props.onInput(floatToInteger(float))
    },
    [props.onChange, props.onInput],
  )
  return <TextInputWithIcon {...{
    position: 'left',
    ...props,
    icon: currencyPicker,
    value: inputValue,
    disabled,
    className: classNames('PriceInput', { className }),
    type: 'number',
    onFocus,
    onChange,
    onInput,
  }}/>
}

PriceInput.propTypes = {
  className: PropTypes.string,
  value: PropTypes.number,
  step: PropTypes.number,
  onChange: PropTypes.func,
  onInput: PropTypes.func,
  onFocus: PropTypes.func,
  currency: PropTypes.oneOf(VALID_CURRENCIES).isRequired,
  disabled: PropTypes.bool,
  currencies: PropTypes.array,
  unsaved: PropTypes.bool,
  onCurrencySelect: PropTypes.func,
}

PriceInput.defaultProps = {
  step: 0.01,
  currency: '$',
}
