import { useCallback, useEffect } from 'preact/hooks'
import { useAppState } from 'lib/appState'

export function useLocation(componentName){
  const {
    appAction,
    location,
  } = useAppState(['location'], componentName)
  return {
    location,
    setLocation: appAction('location.set'),
    replaceLocation: appAction('location.replace'),
    setQuery: appAction('location.setQuery'),
    setPathname: appAction('location.setPathname'),
    pathWithRedirect: pathWithRedirect.bind(null, location),
  }
}

const pathWithRedirect = (currentLocation, newLocation) =>
  newLocation.withQuery({ r: encodeURIComponent(`${currentLocation}`) })


export function useLocationQueryState(options, componentName) {
  const { query, defaultValue, replace } = options
  const { location, setQuery } = useLocation(componentName)
  return [
    location.query[query] || defaultValue,
    useCallback(
      value => setQuery({ [query]: value }, undefined, replace),
      [],
    )
  ]
}

export function useRedirect(options, componentName){
  const {to: location, replace} = options
  let condition
  if ('if' in options) condition = !!options['if']
  const { setLocation, replaceLocation } = useLocation(componentName)
  useEffect(
    () => {
      if (condition === undefined || condition === true)
        (replace ? replaceLocation : setLocation)(location)
    },
    [location, condition, replace]
  )
}
