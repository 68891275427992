import { h, Fragment, Component } from 'preact'
import PropTypes from 'prop-types'
import moment from 'moment'
import { validateBuyingInterest } from 'jlinc-shared/buyingInterests'

import { bindToAppState } from 'lib/appState'

import Form from 'components/Form'
import TagsInput from 'components/TagsInput'
import PriceInput from 'components/PriceInput'
import FormBuilder from 'components/FormBuilder'
import DatePicker from 'components/DatePicker'
import ErrorMessage from 'components/ErrorMessage'

import './index.sass'

class BuyingInterestForm extends Component {

  static propTypes = {
    value: PropTypes.shape({
      description: PropTypes.string,
      currency: PropTypes.string,
      price_low: PropTypes.number,
      price_high: PropTypes.number,
      beginning_date: PropTypes.string,
      end_date: PropTypes.string,
      location: PropTypes.string,
      tags: PropTypes.array,
    }),
    onChange: PropTypes.func.isRequired,
    onCreate: PropTypes.func.isRequired,
    onReset: PropTypes.func.isRequired,
    onSuccess: PropTypes.func,
    creating: PropTypes.bool,
    error: ErrorMessage.propTypes.error,
  }

  onChange = changes => {
    const value = {...this.value(), ...changes}
    this.props.onChange(value)
  }

  value() {
    const {
      beginning_date = moment().format("YYYY-MM-DD"),
      end_date = moment().format("YYYY-MM-DD"),
      currency = '$',
      price_low = 0,
    } = this.props.value || {}

    return {
      ...this.props.value,
      beginning_date,
      end_date,
      currency,
      price_low,
    }
  }

  validate(buyingInterest){
    if (!buyingInterest.location) return 'Location is required'
    if (!buyingInterest.industry) return 'Industry is required'
    if (!buyingInterest.tags || buyingInterest.tags.length === 0)
      return 'At least one product feature is required'
    try{
      validateBuyingInterest(buyingInterest)
    }catch(error){
      return `${error && error.message || error}`
        .replace('Tag', 'Product Feature')
    }
  }

  render() {
    const value = this.value()
    const { onCreate, onReset, creating, error, onSuccess } = this.props

    return <FormBuilder
      className="BuyingInterestForm"
      value={value}
      disabled={creating}
      submitting={creating}
      onChange={this.onChange}
      onReset={onReset}
      onSubmit={onCreate}
      error={error}
      onSuccess={onSuccess}
      validator={this.validate}
      render={form =>
        <Fragment>
          {form.textAreaItem({
            valueProp: 'description',
            name: 'description',
            label: 'ITEM DESCRIPTION',
            required: true,
          })}
          <Form.Row>
            {form.item({
              valueProp: 'price_low',
              label: 'MINIMUM PRICE',
              input: <PriceInput
                onChange={price_low => { this.onChange({price_low}) }}
                onCurrencySelect={currency => { this.onChange({currency}) }}
                currency={value.currency}
              />
            })}
            {form.item({
              valueProp: 'price_high',
              label: 'MAXIMUM PRICE',
              input: <PriceInput
                onChange={price_high => { this.onChange({price_high}) }}
                onCurrencySelect={currency => { this.onChange({currency}) }}
                currency={value.currency}
              />
            })}
          </Form.Row>
          <Form.Row>
            {form.item({
              valueProp: 'beginning_date',
              label: 'BEGINNING DATE',
              input: <DatePicker />
            })}
            {form.item({
              valueProp: 'end_date',
              label: 'END DATE',
              input: <DatePicker />
            })}
          </Form.Row>
          {form.textItem({
            valueProp: 'location',
            name: 'location',
            label: 'LOCATION',
            required: true,
          })}
          {form.textItem({
            valueProp: 'industry',
            name: 'industry',
            label: 'INDUSTRY',
            required: true,
          })}
          {form.item({
            valueProp: 'brands',
            label: 'BRANDS',
            input: <TagsInput placeholder="Enter brands" />
          })}
          {form.item({
            valueProp: 'tags',
            label: 'PRODUCT FEATURES',
            required: true,
            input: <TagsInput placeholder="Enter features" />,
          })}
          <Form.ButtonRow reverse>
            {form.submitButton({
              value: 'Save',
              submittingValue: 'Saving…',
            })}
            {form.resetButton({})}
          </Form.ButtonRow>
        </Fragment>
      }
    />
  }
}


export default bindToAppState(
  [
    'newBuyingInterest',
    'creatingBuyingInterest',
    'creatingBuyingInterestError',
  ],
  BuyingInterestForm
)
