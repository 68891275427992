import { h, Component } from 'preact'
import PropTypes from 'prop-types'

import { focusWithin } from 'lib/DOMHelpers'
import {
  disableDocumentScrolling,
  enableDocumentScrolling,
} from 'lib/modalHelpers'
import IconButton from 'components/IconButton'

import './index.sass'

export default class Modal extends Component {

  static propTypes = {
    onClose: PropTypes.func.isRequired,
    showCloseXButton: PropTypes.bool,
    children: PropTypes.node,
    closeOnShroudClick: PropTypes.bool,
  }

  static defaultProps = {
    closeOnShroudClick: true,
  }

  componentDidMount() {
    disableDocumentScrolling()
    document.body.addEventListener('keydown', this.onKeyDown, {passive:true})
    window.addEventListener('focusin', this.onFocus, {passive:true})
    setTimeout(() => { this.base.scrollTop = 0 }, 0)
    focusWithin(this.base.querySelector('.Modal-window-body'))
  }

  componentWillUnmount() {
    enableDocumentScrolling()
    document.body.removeEventListener('keydown', this.onKeyDown)
    window.removeEventListener('focusin', this.onFocus)
  }

  onKeyDown = event => {
    if (event.code === 'Escape') this.props.onClose()
  }

  onFocus = event => {
    if (!this.base.contains(event.target)){
      // this was closing the HelpAndFeedback modal
      const helpAndFeedback = document.body.querySelector('.HelpAndFeedback')
      if (!helpAndFeedback || !helpAndFeedback.contains(event.target)) this.focus()
    }
  }

  onShroudClick = (event) => {
    if (event.target === this.base && this.props.closeOnShroudClick){
      this.props.onClose()
    }
  }

  onScroll = (event) => {
    event.stopPropagation()
  }

  onWheel = (event) => {
    event.stopPropagation()
  }

  onTouchMove = (event) => {
    event.stopPropagation()
  }

  focus(){
    focusWithin(this.base.querySelector('.Modal-window-body'))
  }

  render({ className = '', modalClassName = '', children, onClose, showCloseXButton }){
    return <div
      className={`Modal ${modalClassName}`}
      onClick={this.onShroudClick}
      onScroll={this.onScroll}
      onWheel={this.onWheel}
      onTouchMove={this.onTouchMove}
    >
      <div className={`Modal-window ${className}`}>
        { showCloseXButton &&
          <IconButton
            type="cancel-circled"
            onClick={onClose}
            className="Modal-closeButton"
          />
        }
        <div className="Modal-window-body">{children}</div>
      </div>
    </div>
  }
}
