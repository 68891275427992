import { h, Component } from 'preact'
import PropTypes from 'prop-types'
import TextInput from 'components/TextInput'

export default class CopyTextInput extends Component {

  static propTypes = {
    className: PropTypes.string,
    value: PropTypes.string.isRequired,
  }

  preventDefault(event){
    event.preventDefault()
  }

  selectAll = (event) => {
    if (event) event.preventDefault()
    TextInput.focus(this.input)
  }

  render({
    className = '',
    value,
  }){

    return <TextInput
      ref={input => { this.input = input }}
      className={`CopyTextInput ${className}`}
      readonly
      value={value}
      onClick={this.selectAll}
      onFocus={this.selectAll}
    />
  }
}
