import { h, Fragment } from 'preact'
import PropTypes from 'prop-types'

import classNames from 'lib/classNames'
import { useCurrentUser } from 'lib/membershipAppStateHooks'
import { useLocation } from 'lib/locationHooks'

import Header from 'components/Header'
import Button from 'components/Button'
import './index.sass'

export default function PageError({
  className,
  header,
  img,
  subtext,
  recommendation,
  ...props
}){
  return <div
    {...props}
    className={classNames('PageError', { className })}
  >
    <Header size="lg" centered>{header}</Header>
    <img className="PageError-img" src={img} />
    <Header size="sm" centered>{subtext}</Header>
    <div>{recommendation || <Recommendation />}</div>
  </div>
}

PageError.propTypes = {
  className: PropTypes.string,
  header: PropTypes.node,
  img: PropTypes.string,
  subtext: PropTypes.node,
  recommendation: PropTypes.node,
}

function Recommendation(){
  const { location } = useLocation('PageError')
  const { currentUser } = useCurrentUser('PageError')
  return currentUser
    ? <Fragment>
      <Button type="normal" href="/" value="Go To Your Feed"/>
    </Fragment>
    : <Fragment>
      <Button type="normal" href={location.toLogin()} value="Login"/>
      <span> or </span>
      <Button type="normal" href={location.toSignup()} value="Signup"/>
    </Fragment>
}
