import { h, Component } from 'preact'
import PropTypes from 'prop-types'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { useAppState } from 'lib/appState'

import Alert from 'components/Alert'
import IconButton from 'components/IconButton'

import './index.sass'

export default class ErrorMessage extends Component {

  static propTypes = {
    error: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Error),
    ]),
    dismissable: PropTypes.bool,
    className: PropTypes.string,
    slideDown: PropTypes.bool,
    onDismiss: PropTypes.func,
  }

  static defaultProps = {
    dismissable: true,
    slideDown: true,
  }

  render(){
    let {error, dismissable, slideDown, onDismiss} = this.props
    let className = 'ErrorMessage'
    if (dismissable) className += ` ErrorMessage-dismissable`
    if (this.props.className) className += ` ${this.props.className}`
    let errorMessageText = error && (error instanceof Error ? error.message : `${error}`)
    if (errorMessageText) errorMessageText = errorMessageText.replace('POST to B server failed: ', '')
    const alert = errorMessageText && <div className="ErrorMessage-alertWrapper">
      { dismissable &&
        (onDismiss
          ? <DismissViaCallback {...{error, onDismiss}}/>
          : <DismissViaAppState {...{error}}/>
        )
      }
      <Alert type="error" className="ErrorMessage-error">
        {errorMessageText}
      </Alert>
    </div>

    if (slideDown) return (
      <TransitionGroup className={className}>
        {alert &&
          <CSSTransition
            key={errorMessageText}
            classNames="ErrorMessage-slideDown"
            timeout={300}
          >{alert}</CSSTransition>
        }
      </TransitionGroup>
    )
    return <div className={className}>{alert}</div>
  }
}

function DismissViaCallback({ error, onDismiss }){
  return <DismissButton
    onClick={() => { onDismiss(error) }}
  />
}

function DismissViaAppState({ error }){
  const { takeAction } = useAppState(undefined, 'ErrorMessage')
  return <DismissButton
    onClick={() => {
      takeAction('ui.dismissError', error)
    }}
  />
}

function DismissButton({ onClick }){
  return <IconButton
    type="cancel-circled"
    className="ErrorMessage-dismissButton"
    size="sm"
    onClick={onClick}
  />
}
