import { h } from 'preact'
import { useEffect, useCallback } from 'preact/hooks'
import { useAppState } from 'lib/appState'
import useLoadedEntity from 'lib/useLoadedEntityHook'
import { isDID } from 'lib/dids'

export function useOrganizationMemberships(membershipUids, componentName){
  const appState = useAppState(
    Array.from(membershipUids).map(uid => `membership:${uid}`),
    componentName,
  )

  const memberships = []
  membershipUids.forEach(uid => {
    const membership = appState[`membership:${uid}`]
    if (!membership) {
      console.warn(`membership uid="${uid}" not found in appState`)
      return
    }
    memberships.push(membership)
  })

  return { memberships }
}

export function useMyOrganizationMemberships(componentName){
  let {
    takeAction,
    uids,
    loaded,
    loading,
    loadingError,
  } = useAppState(
    {
      'myOrganizationMemberships': 'uids',
      'myOrganizationMemberships:loaded': 'loaded',
      'myOrganizationMemberships:loading': 'loading',
      'myOrganizationMemberships:loading:error': 'loadingError',
    },
    componentName,
  )

  useEffect(
    () => {
      if (!loaded) takeAction('organizationMemberships.loadMine')
    },
    [loaded]
  )

  const { memberships: myOrganizationMemberships }
    = useOrganizationMemberships(uids || [], componentName)

  return {
    myOrganizationMemberships,
    myOrganizationMembershipsUids: uids,
    myOrganizationMembershipsLoaded: loaded,
    myOrganizationMembershipsLoading: loading,
    myOrganizationMembershipsLoadingError: loadingError,
  }
}

export function useMyOrganizations(componentName){
  const { myOrganizationMemberships } = useMyOrganizationMemberships(componentName)
  const organizationApikeys = myOrganizationMemberships.map(m => m.organizationApikey)
  const { organizations } = useOrganizations(organizationApikeys, componentName)
  return { myOrganizationMemberships, myOrganizations: organizations }
}

export function useMyOrganizationMembership(organizationApikey, componentName){
  const { myPublicProfileDid } = useMyPublicProfileDid(componentName)
  const { myOrganizationMemberships } = useMyOrganizationMemberships(componentName)
  const organizationMembership = myOrganizationMemberships.find(organizationMembership =>
    organizationMembership.organizationApikey === organizationApikey &&
    organizationMembership.memberUserDid === myPublicProfileDid
  )

  const isMember = !!organizationMembership && !!organizationMembership.acceptedAt
  const isAdmin = isMember && !!organizationMembership.admin
  const isCurator = isAdmin || (isMember && !!organizationMembership.curator)
  return {
    myOrganizationMemberships,
    myOrganizationMembership: organizationMembership,
    organizationMembership, // DEPRECATED
    isMember,
    isAdmin,
    isCurator,
  }
}

export function useMembershipsForOrganizationsIAdmin(componentName){
  const { myOrganizationMemberships } = useMyOrganizationMemberships(componentName)
  const myAdminMemberships = myOrganizationMemberships.filter(membership =>
    (membership.admin || membership.curator) &&
    membership.organizationApikey
  )
  const organizationApikeysIAdmin = new Set(
    myAdminMemberships.map(membership => membership.organizationApikey)
  )
  return {
    myOrganizationMemberships,
    myAdminMemberships,
    organizationApikeysIAdmin,
  }
}

export function useMyPendingOrganizationMemberships(componentName){
  const { myOrganizationMemberships } = useMyOrganizationMemberships(componentName)
  const myPendingOrganizationMemberships = myOrganizationMemberships.filter(membership =>
    !membership.acceptedAt && !membership.rejectedAt
  )
  return { myPendingOrganizationMemberships }
}

export function useMyAcceptedOrganizationMemberships(componentName){
  const { myOrganizationMemberships } = useMyOrganizationMemberships(componentName)
  const myAcceptedOrganizationMemberships = myOrganizationMemberships.filter(
    membership => !!membership.acceptedAt
  )
  return { myAcceptedOrganizationMemberships }
}

export function usePendingMembershipsForOrganization(organizationApikey, componentName) {
  const { memberships } = useMembershipsForOrganization(organizationApikey, componentName)
  const pendingOrganizationMemberships = memberships.filter(membership =>
    !membership.acceptedAt && !membership.rejectedAt
  )
  return { pendingOrganizationMemberships }
}

export function useAcceptedMembershipsForOrganization(organizationApikey, componentName) {
  const { memberships } = useMembershipsForOrganization(organizationApikey, componentName)
  const acceptedOrganizationMemberships = memberships.filter(membership => !!membership.acceptedAt)
  return { acceptedOrganizationMemberships }
}

export function useOrganizationMembershipRequests(organizationApikey, componentName) {
  const { pendingOrganizationMemberships } = usePendingMembershipsForOrganization(organizationApikey, componentName)
  const organizationMembershipRequests = pendingOrganizationMemberships.filter(r =>
    r.memberUserDid === r.createdByUserDid
  )
  return { organizationMembershipRequests }
}

export function useUpdateOrganizationMembership(organizationMembership, componentName){
  const {
    takeAction,
    updatingOrganizationMembership,
    errorUpdatingOrganizationMembership,
  } = useAppState(
    {
      [`organizationMembership:${organizationMembership.uid}:updating`]: 'updatingOrganizationMembership',
      [`organizationMembership:${organizationMembership.uid}:updating:error`]: 'errorUpdatingOrganizationMembership',
    },
    componentName
  )
  const updateOrganizationMembership = useCallback(
    changes => {
      takeAction('organizationMemberships.update', {organizationMembership, changes})
    },
    [organizationMembership],
  )
  return {
    updateOrganizationMembership,
    updatingOrganizationMembership,
    errorUpdatingOrganizationMembership,
  }
}

export function useRemoveOrganizationMembership(componentName){
  const {
    takeAction,
    removingMembership,
    errorRemovingMembership,
  } = useAppState(
    [
      'removingMembership',
      'errorRemovingMembership',
    ],
    componentName
  )
  const removeMembership = useCallback(
    organizationMembership => {
      takeAction('organizationMemberships.remove', {organizationMembership})
    },
    [],
  )
  return {
    removingMembership,
    errorRemovingMembership,
    removeMembership,
  }
}

export function useOrganizationsMemberships(organizationApikeys, componentName){
  const appState = useAppState(
    Array.from(organizationApikeys)
      .map(organizationApikey => `organization:${organizationApikey}:memberships`),
    componentName,
  )

  const organizationsMembershipUids = new Set()
  organizationApikeys.forEach(organizationApikey => {
    const appStateKey = `organization:${organizationApikey}:memberships`
    const membershipUids = appState[appStateKey]
    if (!membershipUids) {
      console.warn(`appState key "${appStateKey}" expected but not found`)
      return
    }
    Array.from(membershipUids).forEach(membershipUid => organizationsMembershipUids.add(membershipUid))
  })

  const {
    memberships: organizationsMemberships,
  } = useOrganizationMemberships(organizationsMembershipUids, componentName)

  return {
    organizationsMembershipUids,
    organizationsMemberships,
  }
}

export function useMembershipsForOrganization(organizationApikey, componentName){
  let {
    membershipUids = new Set(),
  } = useAppState(
    {[`organization:${organizationApikey}:memberships`]: 'membershipUids'},
    componentName,
  )
  membershipUids = [...membershipUids]

  const { memberships } = useOrganizationMemberships(membershipUids, componentName)
  return { memberships, membershipUids }
}

export function useLoadOrganizationMembershipInvite(inviteToken, componentName){
  const {
    takeAction,
    loadingOrganizationMembershipInvite,
    errorLoadingOrganizationMembershipInvite,
    organizationMembershipInvite,
  } = useAppState(
    {
      [`organizationMembershipInvite:${inviteToken}:loading`]: 'loadingOrganizationMembershipInvite',
      [`organizationMembershipInvite:${inviteToken}:loading:error`]: 'errorLoadingOrganizationMembershipInvite',
      [`organizationMembershipInvite:${inviteToken}`]: 'organizationMembershipInvite',
    },
    componentName,
  )

  useEffect(
    () => {
      if (
        inviteToken &&
        !organizationMembershipInvite &&
        !loadingOrganizationMembershipInvite &&
        !errorLoadingOrganizationMembershipInvite
      )
        takeAction('organizationMembershipInvites.loadInvite', inviteToken)
    },
    [
      inviteToken,
      organizationMembershipInvite,
      loadingOrganizationMembershipInvite,
      errorLoadingOrganizationMembershipInvite,
    ],
  )

  return {
    loadingOrganizationMembershipInvite,
    errorLoadingOrganizationMembershipInvite,
    organizationMembershipInvite,
  }
}

export function useOrganizationMembershipInviteFor(organizationApikey, componentName){
  const {
    organizationMembershipInvite,
  } = useAppState(
    {
      [`organizationMembershipInviteTo:${organizationApikey}`]: 'organizationMembershipInvite',
    },
    componentName,
  )

  return {
    organizationMembershipInvite,
  }
}

export function useUserOrganizationMemberships(publicProfileDid, componentName) {
  const { loggedIn } = useCurrentUser(componentName)
  const {
    takeAction,
    loadingUserMemberships,
    errorLoadingUserMemberships,
    userMembershipsUids,
  } = useAppState(
    {
      [`user:${publicProfileDid}:memberships:loading`]: 'loadingUserMemberships',
      [`user:${publicProfileDid}:memberships:loading:error`]: 'errorLoadingUserMemberships',
      [`user:${publicProfileDid}:memberships`]: 'userMembershipsUids',
    },
    componentName,
  )

  useEffect(
    () => {
      if (loggedIn && !userMembershipsUids && !errorLoadingUserMemberships)
        takeAction('organizationMemberships.getForPublicProfile', publicProfileDid)
    },
    [loggedIn, publicProfileDid, errorLoadingUserMemberships],
  )

  const { memberships: userMemberships } = useOrganizationMemberships(userMembershipsUids || [], componentName)

  return {
    loadingUserMemberships: (
      loadingUserMemberships || (!userMembershipsUids && !errorLoadingUserMemberships)
    ),
    errorLoadingUserMemberships,
    userMembershipsUids,
    userMemberships,
  }
}

export function useCreateOrganizationMembership(componentName) {
  const {
    takeAction,
    creatingOrganizationMembership,
    errorCreatingOrganizationMembership,
  } = useAppState(
    [
      'creatingOrganizationMembership',
      'errorCreatingOrganizationMembership',
    ],
    componentName,
  )

  const createOrganizationMembership = useCallback(
    ({ organizationApikey, memberUserDid, inviteToken }) => {
      return takeAction('organizationMemberships.create', { organizationApikey, memberUserDid, inviteToken })
    },
    []
  )

  return {
    creatingOrganizationMembership,
    errorCreatingOrganizationMembership,
    createOrganizationMembership,
  }
}

export function useUpdateOrganizationMembershipError(pendingOrganizationMemberships, componentName) {
  const errorKey = organizationMembership => `organizationMembership:${organizationMembership.uid}:updating:error`
  const {
    ...appState
  } = useAppState([...pendingOrganizationMemberships.map(m => errorKey(m))], componentName)
  let errorUpdating
  for (let organizationMembership of pendingOrganizationMemberships) {
    const error = appState[errorKey(organizationMembership)]
    if (error) {
      errorUpdating = error
      break
    }
  }

  return errorUpdating
}

/*
  ==============================================
  TODO move the hooks below into their own files
  ==============================================
*/

export function useOrganizationsIAdmin(componentName){
  const {
    myOrganizationMemberships,
    myAdminMemberships,
    organizationApikeysIAdmin,
  } = useMembershipsForOrganizationsIAdmin(componentName)
  const { organizations } = useOrganizations(organizationApikeysIAdmin, componentName)
  return {
    myOrganizationMemberships,
    myAdminMemberships,
    organizationsIAdmin: organizations,
    organizationApikeysIAdmin,
  }
}

export function usePublicOrganizations(componentName){
  const {
    publicOrganizationApikeys,
    publicOrganizationApikeysLoading,
    publicOrganizationApikeysLoadingError,
    publicOrganizationApikeysNotFound,
  } = useLoadedEntity({
    entityKey: 'publicOrganizationApikeys',
    loadEntity: takeAction => takeAction('loadPublicOrganizations'),
    componentName,
  })

  const { organizations } = useOrganizations(
    publicOrganizationApikeys || [],
    componentName,
  )

  return {
    publicOrganizationApikeys,
    publicOrganizations: publicOrganizationApikeys && organizations,
    publicOrganizationsLoading: publicOrganizationApikeysLoading,
    publicOrganizationsLoadingError: publicOrganizationApikeysLoadingError,
    publicOrganizationsNotFound: publicOrganizationApikeysNotFound,
  }
}

export function usePublicProfiles(publicProfileDids, componentName){
  publicProfileDids = Array.from(publicProfileDids)
  if (!publicProfileDids.every(isDID)){
    const invalidDids = publicProfileDids.filter(did => !isDID(did))
    console.trace('invalid dids given to usePublicProfiles', invalidDids)
    publicProfileDids = publicProfileDids.filter(isDID)
  }
  const {takeAction, ...appState} = useAppState(
    Array.from(publicProfileDids).map(did => `publicProfile:${did}`),
    componentName
  )
  const missingPublicProfiles = []
  const publicProfiles = []
  publicProfileDids.forEach(did => {
    const publicProfile = appState[`publicProfile:${did}`]
    if (publicProfile) {
      publicProfiles.push(publicProfile)
    }else{
      missingPublicProfiles.push(did)
    }
  })

  useEffect(
    () => {
      if (missingPublicProfiles.length > 0)
        takeAction('publicProfiles.get', { dids: missingPublicProfiles })
    },
    [missingPublicProfiles.length]
  )

  return { publicProfiles }
}

export function usePublicProfile(publicProfileDid, componentName){
  const { publicProfile } = useAppState(
    {[`publicProfile:${publicProfileDid}`]: 'publicProfile'},
    componentName,
  )
  return { publicProfile }
}

export function usePublicProfileByUsername(username, componentName){
  const {
    takeAction,
    publicProfileDid,
  } = useAppState(
    {[`@${username}`]: 'publicProfileDid'},
    componentName,
  )

  const { publicProfile } = usePublicProfile(publicProfileDid, componentName)

  const publicProfileNotfound = publicProfileDid === null
  const loadingPublicProfile = publicProfileDid === undefined
  useEffect(
    () => {
      if (!publicProfileNotfound && loadingPublicProfile)
        takeAction('publicProfiles.get', { usernames: [username] })
    },
    [loadingPublicProfile, publicProfileNotfound]
  )

  return {
    loadingPublicProfile,
    publicProfileNotfound,
    publicProfile,
  }
}

export function usePublicProfileByDid(publicProfileDid, componentName){
  const { takeAction } = useAppState(undefined, componentName)

  const { publicProfile } = usePublicProfile(publicProfileDid, componentName)

  const publicProfileNotfound = publicProfile === null
  const loadingPublicProfile = publicProfile === undefined
  useEffect(
    () => {
      if (!publicProfileNotfound && loadingPublicProfile)
        takeAction('publicProfiles.get', { dids: [publicProfileDid] })
    },
    [publicProfileDid, publicProfileNotfound]
  )

  return {
    loadingPublicProfile,
    publicProfileNotfound,
    publicProfile,
  }
}

export function useMyPublicProfileDid(componentName){
  const { myPublicProfileDid } = useAppState(['myPublicProfileDid'], componentName)
  return { myPublicProfileDid }
}

export function useMyPublicProfile(componentName){
  const {
    appAction,
    myPublicProfileDid,
    updatingMyPublicProfile,
    errorUpdatingMyPublicProfile,
  } = useAppState(
    [
      'myPublicProfileDid',
      'updatingMyPublicProfile',
      'errorUpdatingMyPublicProfile',
    ],
    componentName
  )

  const updateMyPublicProfile = appAction('publicProfiles.update')
  const clearMyPublicProfileError = appAction('publicProfiles.clearError')

  const {
    publicProfile: myPublicProfile,
  } = usePublicProfile(myPublicProfileDid, componentName)

  return {
    myPublicProfile,
    myPublicProfileDid,
    updatingMyPublicProfile,
    errorUpdatingMyPublicProfile,
    updateMyPublicProfile,
    clearMyPublicProfileError,
  }
}

export function useOrganizations(organizationApikeys, componentName){
  organizationApikeys = [...new Set(organizationApikeys)]
  const {
    takeAction,
    appAction: _,
    'organizations:loading:error': organizationsLoadingError,
    ...appState
  } = useAppState(
    [
      'organizations:loading:error',
      ...organizationApikeys.map(apikey => `organization:${apikey}`),
    ],
    componentName,
  )

  const organizations = []
  const organizationsNotLoaded = []
  organizationApikeys.forEach(apikey => {
    const organization = appState[`organization:${apikey}`]
    if (!organization) {
      organizationsNotLoaded.push(apikey)
    }else{
      organizations.push(organization)
    }
  })

  useEffect(
    () => {
      if (organizationsNotLoaded.length === 0) return
      takeAction('organizations.load', organizationsNotLoaded)
    },
    [organizationsNotLoaded.length]
  )
  return {
    organizations,
    organizationsLoadingError,
    organizationsLoading: organizationsNotLoaded.length > 0,
  }
}

export function useCanonicalOrganizationApikeyRedirector(organizationApikey, componentName){
  const {
    location,
    takeAction,
    canonicalOrganizationApikey,
  } = useAppState(
    {
      location: 'location',
      [`organization:${organizationApikey}:canonical`]: 'canonicalOrganizationApikey',
    },
    componentName,
  )

  useEffect(
    () => {
      if (canonicalOrganizationApikey){
        const pathname = location.pathname.replace(organizationApikey, canonicalOrganizationApikey)
        if (pathname !== location.pathname)
          takeAction('location.replace', {...location, pathname})
      }
    },
    [canonicalOrganizationApikey]
  )

  return {
    canonicalOrganizationApikey,
  }
}

export function useOrganization(organizationApikey, componentName){
  const {
    canonicalOrganizationApikey,
  } = useAppState(
    {
      [`organization:${organizationApikey}:canonical`]: 'canonicalOrganizationApikey',
    },
    componentName,
  )

  if (canonicalOrganizationApikey) organizationApikey = canonicalOrganizationApikey

  return useLoadedEntity({
    entityName: 'organization',
    entityKey: `organization:${organizationApikey}`,
    loadEntity: takeAction => {
      if (organizationApikey) takeAction('organization.load', organizationApikey)
    },
    componentName,
  })
}

export function useMySISAs(componentName){
  const { mySISAs } = useAppState(['mySISAs'], componentName)
  return { mySISAs }
}

export function useSisa(organizationApikey, componentName){
  const {
    sisa
  } = useAppState(
    {[`sisa:${organizationApikey}`]: 'sisa'},
    componentName
  )
  return { sisa }
}

export function useSisaEvents(organizationApikey, componentName){
  const {
    takeAction,
    sisaEventIds,
  } = useAppState(
    {
      [`sisaEvents:${organizationApikey}`]: 'sisaEventIds',
    },
    componentName,
  )

  useEffect(
    () => {
      if (!sisaEvents) takeAction('organization.loadSisaEvents', organizationApikey)
    }
  )

  const appState = useAppState(
    (sisaEventIds || []).map(sisaEventId => `sisaEvent:${sisaEventId}`),
    componentName,
  )

  if (!sisaEventIds) return {}

  const sisaEvents = []
  sisaEventIds.forEach(sisaEventId => {
    const sisaEvent = appState[`sisaEvent:${sisaEventId}`]
    if (sisaEvent){
      sisaEvents.push(sisaEvent)
    }else{
      console.warn(`sisaEvent id="${sisaEventId}" not found in appState`)
    }
  })


  return { sisaEventIds, sisaEvents }
}

export function usePreferences(componentName) {
  const {
    appAction,
    preferences,
    updatingPreferences,
    updatingPreferencesError,
  } = useAppState(
    [
      'preferences',
      'updatingPreferences',
      'updatingPreferencesError',
    ],
    componentName
  )

  const updatePreferences = appAction('updatePreferences')

  return {
    preferences,
    updatingPreferences,
    updatingPreferencesError,
    updatePreferences,
  }
}

export function useCurrentUser(componentName) {
  const {currentUser} = useAppState(['currentUser'], componentName)
  return {currentUser, loggedIn: !!currentUser}
}


export function byWeightDescCreatedAtDesc(a, b){
  a = a.membership
  b = b.membership
  return a.weight !== b.weight
    ? a.weight - b.weight
    : createdAt(b) - createdAt(a)
}
const createdAt = x => new Date(x.createdAt || x.created).getTime()
