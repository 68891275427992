import { h } from 'preact'
import { useRef, useEffect } from 'preact/hooks'
import PropTypes from 'prop-types'
import Link from 'components/Link'
import classNames from 'lib/classNames'
import './index.sass'

export default function Button({
  className = '',
  type,
  block,
  submit,
  href,
  pathname,
  query,
  value,
  children,
  fat,
  tabIndex,
  ...props
}){
  const ref = useRef()
  let Component = 'button'

  const classType = type || 'normal'
  className = classNames(
    'Button',
    {
      className,
      [classType]: classType,
      block,
      fat,
    }
  )

  if (href || pathname || query) {
    Component = Link
    Object.assign(props, { href, pathname, query })
    // this prevents Link from setting tabIndex=-1
    if (tabIndex === undefined) tabIndex = 0
  }else{
    Component = 'button'
    props.type = submit ? 'submit' : 'button'
  }

  useEffect(
    () => {
      let element = ref.current
      if (element.base) element = element.base
      if (!element) return
      const blur = () => {
        element.blur()
        setTimeout(() => { element.blur() }, 0)
      }
      const clickOnSpace = event => {
        if (event.code === 'Space'){
          event.preventDefault()
          element.click()
          return false
        }
      }
      element.addEventListener('mousedown', blur, {passive:true})
      element.addEventListener('keydown', clickOnSpace)
      return () => {
        element.removeEventListener('mousedown', blur)
        element.removeEventListener('keydown', clickOnSpace)
      }
    },
    [Component]
  )

  return <Component
    {...props}
    className={className}
    ref={ref}
    tabIndex={tabIndex}
  >
    { value ? value : children }
  </Component>
}

Button.TYPES = [
  'none',
  'subtle',
  'normal',
  'success',
  'primary',
  'transparent',
]

Button.propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf([
    ...Button.TYPES,
    ...Link.TYPES,
  ]).isRequired,
  block: PropTypes.bool,
  submit: PropTypes.bool,
  href: PropTypes.string,
  pathname: PropTypes.string,
  query: PropTypes.string,
  value: PropTypes.node,
  disabled: PropTypes.bool,
  fat: PropTypes.bool,
  children: PropTypes.node,
  tabIndex: PropTypes.number,
}

Button.defaultProps = {
  className: '',
  type: 'none',
  block: false,
  submit: false,
  disabled: false,
  fat: false,
}
