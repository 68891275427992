import { h, Component } from 'preact'
import PropTypes from 'prop-types'
import history from 'lib/history'
import { locationToHref } from 'lib/location'
import './index.sass'

const TYPES = Object.freeze(['none', 'text', 'link', 'subtle'])

export default class Link extends Component {

  static propTypes = {
    children: PropTypes.node.isRequired,
    href: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        pathname: PropTypes.string,
        query: PropTypes.object,
      }),
    ]),
    pathname: PropTypes.string,
    query: PropTypes.object,
    onClick: PropTypes.func,
    type: PropTypes.oneOf(TYPES),
    disabled: PropTypes.bool,
    newWindow: PropTypes.bool,
    params: PropTypes.object,
    replace: PropTypes.bool,
    forcePageReload: PropTypes.bool,
    goBack: PropTypes.bool,
    tabIndex: PropTypes.number,
  }

  static defaultProps = {
    type: 'none',
    replace: false,
    forcePageReload: false,
    goBack: false,
  }

  componentDidMount(){
    this.link.addEventListener('mousedown', this.blur, {passive:true})
  }

  componentWillUnmount(){
    this.link.removeEventListener('mousedown', this.blur)
  }

  static TYPES = TYPES

  getHref(){
    let { href, pathname, query } = this.props
    if (href && (href.pathname || href.query))
      ({ pathname, query } = href)
    if (pathname || query) return locationToHref({ pathname, query })
    if (typeof href === 'string') return href
  }

  onClick = event => {
    const { disabled, onClick, replace, forcePageReload, goBack } = this.props
    const href = this.getHref()
    if (disabled) {
      event.preventDefault()
      return
    }

    if (goBack) {
      event.preventDefault()
      history.back()
    }

    if (onClick) {
      try{ onClick(event) }catch(error){
        console.error(error)
        event.preventDefault()
      }
    }

    if (event.defaultPrevented) return

    if (
      !forcePageReload &&
      !event.ctrlKey &&
      !event.metaKey &&
      !event.shiftKey &&
      history.hrefIsSameOrigin(href)
    ){
      event.preventDefault()
      history[replace ? 'replaceState' : 'pushState'](null, global.document.title, href)
    }
  }

  blur = () => {
    this.link.blur()
    setTimeout(() => { this.link && this.link.blur() }, 0)
  }

  render({
    children,
    onClick,
    type,
    newWindow,
    forcePageReload,
    goBack,
    tabIndex,
    ...props
  }){
    const href = this.getHref()
    delete props.replace
    if (props.params){
      // TODO add params to href
      delete props.params
    }

    let className = 'Link'
    if (type) className += ` Link-${type}`
    if (props.className) className += ` ${props.className}`

    if (!href && !onClick && !goBack && !forcePageReload){
      return <span
        ref={node => { this.link = node }}
        {...props}
        className={className}
      >{children}</span>
    }

    const externalLink = href && !history.hrefIsSameOrigin(href)

    if (externalLink){
      props.rel = "noopener noreferrer nofollow"
    }

    if (newWindow || (newWindow !== false && externalLink)){
      props.target = '_blank'
    }

    return <a
      {...props}
      ref={node => { this.link = node }}
      className={className}
      href={props.disabled ? undefined : href}
      onClick={this.onClick}
      tabIndex={tabIndex}
    >
      {children}
    </a>
  }
}
