import { h, Component } from 'preact'
import PropTypes from 'prop-types'

import { submitParentForm } from 'lib/DOMHelpers'

import './index.sass'

export default class TextArea extends Component {

  static propTypes = {
    className: PropTypes.string,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    children: PropTypes.node,
    onChange: PropTypes.func,
    onInput: PropTypes.func,
    onClick: PropTypes.func,
    onKeyUp: PropTypes.func,
    onKeyDown: PropTypes.func,
    disabled: PropTypes.bool,
    unsaved: PropTypes.bool,
    maxLines: PropTypes.number,
    maxLength: PropTypes.number,
    immutable: PropTypes.bool,
    style: PropTypes.object,
    resize: PropTypes.oneOf(['none', 'both', 'horizontal', 'vertical']).isRequired,
    autoResizeVertically: PropTypes.bool,
    minRows: PropTypes.number,
    maxRows: PropTypes.number,
    showCounter: PropTypes.bool,
  }

  static defaultProps = {
    resize: 'vertical',
    minRows: 2,
    maxRows: 10,
  }

  getValue(){
    const { maxLines, maxLength } = this.props
    let value = this.textarea.value
    if (typeof maxLines === 'number')
      value = value.split("\n").slice(0, maxLines).join("\n")
    if (
      typeof maxLength === 'number' &&
      value.length > maxLength
    )
      value = value.substr(0, maxLength)
    if (value === this.props.value)
      this.textarea.value = value
    return value
  }

  onInput = () => {
    if (this.props.onInput) this.props.onInput(this.getValue())
  }

  onChange = () => {
    if (this.props.onChange) this.props.onChange(this.getValue())
  }

  onKeyDown = event => {
    if (event.metaKey && event.key === 'Enter'){
      event.preventDefault()
      submitParentForm(event.target)
    }
    if (this.props.onKeyDown) this.props.onKeyDown(event)
  }

  onKeyUp = event => {
    if (this.props.onKeyUp) this.props.onKeyUp(event)
  }

  onClick = event => {
    if (this.props.onClick) this.props.onClick(event)
  }

  focus(){
    return this.textarea.focus()
  }

  preventChange = event => {
    if (
      event.type === 'keydown' &&
      (
        event.metaKey ||
        event.key.startsWith('Arrow')
      )
    ) return
    event.preventDefault()
    return false
  }

  render(){
    let {
      className = '',
      unsaved,
      value = '',
      immutable,
      resize,
      style,
      autoResizeVertically,
      showCounter,
      minRows,
      maxRows,
      placeholder,
      ...props
    } = this.props
    delete props.onClick
    delete props.onInput
    delete props.onChange
    delete props.onKeyDown
    if (autoResizeVertically) resize = 'none'

    className = `TextArea ${className}`
    if (unsaved) className += ' TextArea-unsaved'
    if (immutable) className += ' TextArea-immutable'
    if (autoResizeVertically) className += ` TextArea-autoResize`
    if (resize) className += ` TextArea-resize-${resize}`


    const sizeStyles = {
      minHeight: rowsToHeight(minRows),
      maxHeight: rowsToHeight(maxRows),
    }

    style = {
      ...sizeStyles,
      ...(style || {})
    }
    if (autoResizeVertically) delete style.height

    return <div
      className={className}
      onClick={this.onClick}
    >
      {
        showCounter &&
        typeof props.maxLength === 'number' &&
        <Counter {...{value, maxLength: props.maxLength}}/>
      }
      {autoResizeVertically &&
        <div
          ref={div => { this.div = div }}
          style={style}
        >
          {(value || placeholder).replace(/(\n|\s)$/, '$1*')}
        </div>
      }
      <textarea
        {...props}
        style={style}
        placeholder={placeholder}
        value={value}
        ref={textarea => { this.textarea = textarea }}
        onKeyUp={this.onKeyUp}
        onKeyDown={immutable ? this.preventChange : this.onKeyDown}
        onInput={immutable ? this.preventChange : this.onInput}
        onChange={immutable ? this.preventChange : this.onChange}
      />
    </div>
  }
}

function Counter({value, maxLength}){
  let length = typeof value === 'string' ? value.length : 0
  return <small className="TextArea-Counter">
    <span>{length}</span> / <span>{maxLength}</span>
  </small>
}


const rowsToHeight = rows =>
  typeof rows === 'number'
    ? `calc(var(--line-height) * ${rows})`
    : undefined
