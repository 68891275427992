import { h } from 'preact'
import { useRef, useEffect, useCallback, useMemo } from 'preact/hooks'
import PropTypes from 'prop-types'

import classNames from 'lib/classNames'

import './index.sass'

export default function TextInput(props){
  let {
    disabled,
    unsaved,
    className,
    onClick,
    type,
    password,
    value,
    lpignore,
    autoFocus,
    trim,
    ...inputProps
  } = props
  delete inputProps.class

  if (password){
    type = 'password'
    trim = false
  }

  const inputRef = useRef()

  useEffect(
    () => {
      if (autoFocus) inputRef.current.focus()
    },
    [autoFocus]
  )

  const onChange = useMemo(
    () => props.onChange && (event => props.onChange(event.target.value, event)),
    [props.onChange],
  )
  const onInput = useMemo(
    () => props.onInput && (event => props.onInput(event.target.value, event)),
    [props.onInput],
  )

  const onBlur = useCallback(
    (event) => {
      if (trim && (props.onChange || props.onInput)){
        const { value } = event.target
        const trimmedValue = value.trim()
        if (trimmedValue !== value){
          if (props.onChange) props.onChange(trimmedValue)
          else if (props.onInput) props.onInput(trimmedValue)
        }
      }
      if (props.onBlur) return props.onBlur(event)
    },
    [props.onBlur, props.onChange, props.onInput, trim]
  )

  return <div {...{
    className: classNames('TextInput', {unsaved, disabled, className}),
    onClick,
    disabled: disabled ? 'disabled' : undefined,
  }}>
    <input {...{
      ...inputProps,
      disabled,
      ref: inputRef,
      type,
      value: `${value || ''}`,
      'data-lpignore': lpignore ? 'true' : 'false',
      onChange,
      onInput,
      onBlur,
    }}/>
  </div>
}

// https://developer.mozilla.org/en-US/docs/Web/HTML/Element/Input#Form_%3Cinput%3E_types
TextInput.TYPES = Object.freeze(
  `
  date datetime datetime-local email
  month number password search tel
  text time url week
  `.trim().split(/\s+/).sort()
)

TextInput.propTypes = {
  disabled: PropTypes.bool,
  unsaved: PropTypes.bool,
  className: PropTypes.string,
  onChange: PropTypes.func,
  onInput: PropTypes.func,
  onClick: PropTypes.func,
  onBlur: PropTypes.func,
  type: PropTypes.oneOf(TextInput.TYPES),
  password: PropTypes.bool,
  name: PropTypes.string,
  value: PropTypes.string,
  lpignore: PropTypes.bool,
  autoFocus: PropTypes.bool,
  trim: PropTypes.bool,
}

TextInput.defaultProps = {
  className: '',
  type: 'text',
  lpignore: true,
  trim: true,
  autoCapitalize: 'none',
  autoCorrect: 'off',
}

TextInput.focus = textInput => {
  if (textInput.current) textInput = textInput.current
  if (textInput.base) textInput = textInput.base
  textInput.firstElementChild.focus()
}
