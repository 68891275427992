import EventEmitter from 'events'

import logger from 'lib/logger'
import NetworkError from 'lib/NetworkError'
import querystring from 'querystring'

const HEADERS = {
  'Accept': 'application/json',
  'Content-Type': 'application/json;charset=UTF-8',
}


function log(type, method, url, data, error){
  error = error ? ` ${error}` : ''
  const arrow = (
    type === 'request' ? '==>' :
    type === 'response' ? '<==' :
    ''
  )
  logger.debugWithObjectCollapsed(
    `%c[${type}] %c${method} ${arrow} %c${url} %c${error}`,
    'color: plum;',
    'color: magenta;',
    'color: inherit;',
    'color: red;',
    data
  )
}

export default class JLINCAPI extends EventEmitter {

  constructor({ urlPrefix }){
    super()
    this.urlPrefix = urlPrefix
    this.sessionId = null
  }

  postJSON(path, postBody) {
    const url = `${this.urlPrefix}${path}`
    log('request', 'POST', url, {postBody})
    const headers = {...HEADERS}
    if (this.sessionId) headers['Session-Id'] = this.sessionId
    return fetch(url, {
      method: 'POST',
      headers,
      body: JSON.stringify(postBody)
    })
      .catch(error => {
        logResponseError(error, 'POST', url, {postBody})
      })
      .catch(makeErrorHumanReadable)
      .then(parseJSON)
      .then(response => {
        log('response', 'POST', url, {postBody, responseJSON: response.data})
        return response
      })
      .then(throwErrorIfSuccessFalse)
      .catch(handleInvalidSession.bind(this))
      .catch(stitchErrorStack())
  }

  getJSON(path, params = {}){
    stripUndefinedProps(params)
    const url = `${this.urlPrefix}${path}`
    const query = querystring.stringify(params)
    const urlWithQuery = query ? `${url}?${query}` : url
    log('request', 'GET', urlWithQuery, {params})
    const headers = {...HEADERS}
    if (this.sessionId) headers['Session-Id'] = this.sessionId
    return fetch(urlWithQuery, {
      method: 'GET',
      headers,
    })
      .catch(error => {
        logResponseError(error, 'GET', urlWithQuery, {params})
      })
      .catch(makeErrorHumanReadable)
      .then(parseJSON)
      .then(response => {
        log('response', 'GET', urlWithQuery, {params, responseJSON: response.data})
        return response
      })
      .then(throwErrorIfSuccessFalse)
      .catch(handleInvalidSession.bind(this))
      .catch(stitchErrorStack())
  }

}

function stitchErrorStack(){
  const stack = (new Error()).stack.split('\n').slice(1).join('\n')
  return error => {
    error.stack += '\n' + stack
    throw error
  }
}

function stripUndefinedProps(params){
  for(const param in params)
    if (params[param] === undefined)
      delete params[param]
}

const handleInvalidSession = function(error){
  if (`${error}`.includes('invalid session')){
    setTimeout(() => { this.emit('invalidSession', error) }, 1)
  }
  throw error
}

// conform to JLINC API standard json response
const throwErrorIfSuccessFalse = function(response){
  let { success, error, ...data } = response.data
  if (success) return data
  error = new Error(error)
  error.response = response
  throw error
}

const parseJSON = function(response){
  return response.json().then(data => {
    response.data = data
    return response
  })
}

const logResponseError = function(error, method, url, data){
  log('response', method, url, {error, ...data}, error)
  throw error
}

const makeErrorHumanReadable = function(error){
  if (error.message.includes('Failed to fetch')){
    throw new NetworkError()
  }
  throw error
}
