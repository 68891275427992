import logger from './logger'

export default function(error) {
  if (!(error instanceof Error) && typeof error !== 'string'){
    console.error(`refusing to dismiss error`, error)
    return
  }
  const state = this.getState()

  for (let key in state) {
    if (state[key] === error) {
      logger.debug(`dismissing error in state[${key}]`, {error})
      this.setState({ [key]: undefined })
    }
  }
}
