import moment from 'moment'
import { h, Component } from 'preact'
import PropTypes from 'prop-types'

export default class Timestamp extends Component {

  static propTypes = {
    className: PropTypes.string,
    time: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date),
      PropTypes.instanceOf(moment),
    ]).isRequired,
    format: PropTypes.string.isRequired,
  }

  static defaultProps = {
    format: 'MMM Do YYYY h:mmA',
  }

  render(){
    let { className = '', time, format, ...props } = this.props
    if (format in Timestamp.formats) format = Timestamp.formats[format]
    const timeAsMoment = moment(time)
    return <span
      {...props}
      className={`Timestamp ${className}`}
      title={timeAsMoment.format(Timestamp.formats.full)}
    >
      {timeAsMoment.format(format)}
    </span>
  }
}

Timestamp.formats = Object.freeze({
  date: 'MMM Do YYYY',
  time: 'h:mmA',
  full: 'dddd, MMMM Do YYYY, h:mm:ssa',
})
