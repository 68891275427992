import { h, Component, cloneElement } from 'preact'
import { shallowDiffers } from 'preact/compat/src/util'
import PropTypes from 'prop-types'

import ErrorMessage from 'components/ErrorMessage'
import Form from 'components/Form'
import Button from 'components/Button'
import TextInput from 'components/TextInput'
import TextArea from 'components/TextArea'
import RequiredStar from 'components/RequiredStar'
// NOTE: we need to keep this as lean as possible
// so strongly consider not adding any more dependencies
// unless they're super general and useful in most forms.


export default class FormBuilder extends Component {

  static propTypes = {
    className: PropTypes.string,
    render: PropTypes.func.isRequired,

    error: ErrorMessage.propTypes.error,
    errorDismissable: PropTypes.bool,
    onDismissError: PropTypes.func,
    defaultValue: PropTypes.object,
    value: PropTypes.object.isRequired,
    persistedValues: PropTypes.object,

    disabled: PropTypes.bool,
    submittable: PropTypes.bool,
    submitting: PropTypes.bool,

    validator: PropTypes.func,
    onChange: PropTypes.func.isRequired,
    onReset: PropTypes.func,
    clearError: PropTypes.func,
    onSubmit: PropTypes.func.isRequired,
    onSuccess: PropTypes.func,
    displayError: PropTypes.bool.isRequired,
  }

  static defaultProps = {
    errorDismissable: true,
    displayError: true,
  }

  constructor(props){
    super(props)
    this.helpers = new FormBuilderHelpers()
    const changes = applyDefaults(props.value, props.defaultValue)
    if (changes) props.onChange(...changes)
  }

  componentWillReceiveProps(nextProps) {
    if (
      (!this.props.error && nextProps.error) ||
      (
        this.props.value !== nextProps.value &&
        typeof this.props.value === 'object' &&
        typeof nextProps.value === 'object' &&
        shallowDiffers(this.props.value, nextProps.value)
      )
    ) this.setState({ error: null })
    if (
      nextProps.onSuccess &&
      this.props.submitting &&
      !nextProps.submitting &&
      !nextProps.error
    ) nextProps.onSuccess()
  }

  state = {
    error: null,
  }

  setError = (error, cb) => {
    this.setState({ error }, cb)
  }

  clearError = () => {
    this.setState({ error: null }, () => {
      if (this.props.clearError) this.props.clearError()
    })
  }

  hasChanges(){
    let { value, persistedValues } = this.props
    if (!value) return false
    if (persistedValues){
      value = {...value}
      for (const key in value)
        if (value[key] === persistedValues[key])
          delete value[key]
    }
    return Object.keys(value).length > 0
  }

  getValue = () => {
    return {
      ...this.props.defaultValue,
      ...this.props.value,
    }
  }

  isInvalid = () => {
    if (!this.props.validator) return
    delete this.validationError
    try{
      this.validationError = this.props.validator(
        this.getValue(),
        this.props.persistedValues
      )
    }catch(error){
      this.validationError = error
    }
    if (this.validationError)
      console.warn('FormBuilder validation error', this.validationError)
    return this.validationError
  }

  validate = () => {
    const validationError = this.isInvalid()
    if (validationError) this.setError(validationError)
  }

  isSubmittable(){
    const { submitting, disabled, submittable } = this.props
    return (
      !submitting && !disabled &&
      ((typeof submittable !== 'undefined') ? !!submittable : this.hasChanges())
    )
  }

  onSubmit = () => {
    if (!this.isSubmittable()) return
    const validationError = this.isInvalid()
    this.setError(validationError, () => {
      if (!validationError) this.props.onSubmit(this.getValue())
    })
  }

  render(){
    const {
      className,
      render,
      defaultValue,
      value,
      persistedValues,
      disabled,
      submitting,
      onChange,
      onReset,
      displayError,
    } = this.props

    const error = this.state.error || this.props.error
    const onDismissError = this.state.error
      ? this.clearError
      : this.props.onDismissError
    const errorDismissable = !!(this.state.error || this.props.errorDismissable)

    this.helpers.update({
      error,
      errorDismissable,
      onDismissError,
      defaultValue,
      value,
      persistedValues,
      disabled,
      submitting,
      hasChanges: this.hasChanges(),
      isSubmittable: this.isSubmittable(),
      onChange,
      onReset,
      setError: this.setError,
      clearError: this.clearError,
      validate: this.validate,
      onSubmit: this.onSubmit,
    })

    return <Form className={className} onSubmit={this.onSubmit}>
      {displayError && this.helpers.errorMessage()}
      {render(this.helpers)}
    </Form>
  }
}

class FormBuilderHelpers {

  update(options){
    this.error            = options.error
    this.errorDismissable = options.errorDismissable
    this.onDismissError   = options.onDismissError
    this.defaultValue     = options.defaultValue
    this.value            = options.value
    this.persistedValues  = options.persistedValues
    this.submitting       = !!options.submitting
    this.disabled         = !!(options.disabled || options.submitting)
    this.hasChanges       = options.hasChanges
    this.isSubmittable    = options.isSubmittable
    this._onChange        = options.onChange
    this.onReset          = options.onReset
    this.setError         = options.setError
    this.clearError       = options.clearError
    this.validate         = options.validate
    this.onSubmit         = options.onSubmit
  }

  /*
   I'm not sure about this hack, but this solves a chrome browser value autofill case
   where it updates two values one right after the other and the previous change is
   overriddem with the previous value

   So this hack replaces our internal cache of the value with the new change in-case
   onChange is called again before the update has a change to re-render
   */
  onChange(value, changes){
    this._onChange(value, changes)
    this.value = value
  }

  change(changes){
    if (!changes) return
    let newValue
    if (this.value){
      for (const key in changes)
        if (changes[key] === this.value[key])
          delete changes[key]
      if (Object.keys(changes).length === 0) return
      newValue = { ...this.value, ...changes }
    }else{
      newValue = { ...changes }
    }
    if (this.persistedValues)
      for (const key in changes)
        if (changes[key] === this.persistedValues[key])
          delete newValue[key]
    this.onChange(newValue, changes)
  }

  submit(){
    this.onSubmit()
  }

  reset = () => {
    this.clearError()
    if (this.onReset) {
      this.onReset()
    }else{
      const changes = applyDefaults(this.value, this.defaultValue)
      if (changes) this.onChange(...changes)
      else this.onChange()
    }
  }

  getValue(valueProp) {
    for (const key of ['value', 'persistedValues', 'defaultValue'])
      if (this[key] && valueProp in this[key])
        return this[key][valueProp]
  }

  isUnsaved(valueProp){
    return (
      this.persistedValues &&
      this.value &&
      valueProp in this.value &&
      this.value[valueProp] !== this.persistedValues[valueProp]
    )
  }

  getInputProps(valueProp){
    const props = {}
    props.value = this.getValue(valueProp)
    props.unsaved = this.isUnsaved(valueProp)
    if (this.disabled) props.disabled = true
    return props
  }

  errorMessage(){
    const { error, errorDismissable, onDismissError } = this
    return <ErrorMessage {...{
      error,
      dismissable: errorDismissable,
      onDismiss: onDismissError,
    }}/>
  }

  bindInput({ input, valueProp, bindTo = 'onChange', unsaved, validateOnChange = false, ...props }){
    if (valueProp){
      props = {
        ...props,
        ...this.getInputProps(valueProp),
      }
    }
    if (typeof unsaved === 'boolean') props.unsaved = unsaved
    if (valueProp && bindTo) {
      const callback = input.props[bindTo]
      props[bindTo] = value => {
        if (callback) callback(value)
        this.change({ [valueProp]: value })
        if (bindTo === 'onChange' && validateOnChange) setTimeout(this.validate, 0)
      }
    }
    props.onError = error => {
      console.error('FormError', error)
      this.setError(error)
    }
    return cloneElement(input, props)
  }

  item({valueProp, label, className, required, input, ...props}){
    return <Form.Item className={className}>
      {label && <Form.Label>{label}{required && <RequiredStar/>}</Form.Label>}
      {this.bindInput({input, valueProp, required, ...props})}
    </Form.Item>
  }

  textItem({
    valueProp,
    bindTo = 'onInput',
    label,
    className,
    required,
    ...props
  }){
    return this.item({
      valueProp,
      bindTo,
      label,
      className,
      required,
      input: <TextInput {...{...props, required}}/>,
    })
  }

  passwordItem({
    valueProp = 'password',
    bindTo = 'onInput',
    label = 'PASSWORD',
    type = 'password',
    name = 'password',
    ...props
  }){
    return this.textItem({valueProp, bindTo, label, type, name, ...props})
  }

  passwordConfirmationItem({
    valueProp = 'passwordConfirmation',
    bindTo = 'onInput',
    label = 'PASSWORD CONFIRMATION',
    type = 'password',
    name = 'password-confirmation',
    ...props
  }){
    return this.textItem({valueProp, bindTo, label, type, name, ...props})
  }

  textAreaItem({
    valueProp,
    bindTo = 'onInput',
    label,
    required,
    ...props
  }){
    return this.item({
      valueProp,
      bindTo,
      label,
      required,
      input: <TextArea {...{...props, required}}/>,
    })
  }

  submitButton({
    disabled,
    value = 'Submit',
    submittingValue = 'Submitting…',
    ...props
  } = {}){
    return <Button
      type={
        (this.persistedValues && this.hasChanges)
          ? 'success'
          : 'primary'
      }
      {...props}
      submit
      disabled={disabled || this.disabled || !this.isSubmittable}
      value={this.submitting && submittingValue || value}
    />
  }

  resetButton({
    disabled,
    value = 'reset',
    onClick,
    confirm,
    ...props
  } = {}){
    return <Button
      type="normal"
      {...props}
      disabled={
        disabled ||
        this.disabled ||
        (!this.hasChanges && !this.error)
      }
      value={value}
      onClick={event => {
        if (
          confirm &&
          !window.confirm(confirm) // eslint-disable-line
        ) return
        if (onClick) onClick(event)
        event.preventDefault()
        this.reset()
      }}
    />
  }

  buttonRow(value, submittingValue){
    return <Form.ButtonRow>
      {this.resetButton({})}
      {this.submitButton({value, submittingValue})}
    </Form.ButtonRow>
  }
}


function applyDefaults(value, defaultValue){
  if (!defaultValue) return
  const propsMissingDefault = Array.from(
    new Set([
      ...Object.keys(value),
      ...Object.keys(defaultValue),
    ])
  ).filter(prop => prop in defaultValue && !(prop in value))
  if (propsMissingDefault.length === 0) return
  const changes = {}
  for(const prop of propsMissingDefault) changes[prop] = defaultValue[prop]
  const newValue = {...value, ...changes}
  return [newValue, changes]
}
