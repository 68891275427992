import { h } from 'preact'

import Link from 'components/Link'
import './index.sass'

export default function SisaText({text, organization, onSummaryClick}){

  const matchInsideBracket = /\[([^\]]+)\]/g
  const updatedText = text
    .split(matchInsideBracket)
    .map((textBlock, index) => {
      if (textBlock === 'Organisation')
        return <span key={index}>{organization.name}</span>
      if (textBlock === 'SISA Summary' || textBlock === 'BISA Summary')
        return <SummaryLink key={index} onSummaryClick={onSummaryClick}>{textBlock}</SummaryLink>
      return textBlock
    })

  return <span className="SisaText">{updatedText}</span>
}

const SummaryLink = ({onSummaryClick, children}) => (
  <Link type="link" onClick={onSummaryClick}>{children}</Link>
)
