import { h } from 'preact'
import PropTypes from 'prop-types'

import { createClassComponent } from 'lib/preactHelpers'

import './index.sass'

export default function Form({
  className = '',
  onSubmit,
  children,
  disabled,
  ...props
}){
  props.onSubmit = event => {
    event.preventDefault()
    if (!disabled && onSubmit) onSubmit(event)
  }
  return <form
    className={`Form ${className}`}
    {...props}
  >
    {disabled || <input type="submit" style={{display: 'none'}}/>}
    {children}
  </form>
}

Form.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  onSubmit: PropTypes.func,
  disabled: PropTypes.bool,
}

Form.Row = createClassComponent('Form-Row')
Form.Row.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
}

Form.Item = createClassComponent('Form-Item')
Form.Item.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
}

Form.Label = createClassComponent('Form-Label')
Form.Label.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
}

Form.LabelSuffix = ({
  className = '',
  children,
}) => <div className={`Form-LabelSuffix ${className}`}>
  &nbsp;{children}
</div>

Form.ButtonRow = ({reverse, children, ...props}) => {
  let className = 'Form-ButtonRow'
  if (reverse) className += ` Form-ButtonRow-reverse`
  if (props.className) className += ` ${props.className}`
  return <div className={className}>{children}</div>
}
